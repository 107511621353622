<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Create Currency</h3>
    </div>

    <!-- Content Row -->
    <CurrencyForm></CurrencyForm>

  </div>
</template>

<script>
import CurrencyForm from "./CurrencyForm";
export default {
  name: "CreateCurrency",
  components: {CurrencyForm}
}
</script>

<style scoped>

</style>