<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Code<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.code" placeholder="" />
        <small v-if="errors && errors.code" class="code_error text-danger">
          {{ errors.code[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Symbol<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.symbol" placeholder="" />
        <small v-if="errors && errors.symbol" class="symbol_error text-danger">
          {{ errors.symbol[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Rate<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.rate" type="text" />
        <small v-if="errors && errors.rate" class="text-danger">
          {{ errors.rate[0] }}
        </small>
      </div>
    </div>
    <div class="form-group form-check">
      <input
          required
          type="checkbox"
          class="form-check-input"
          id="agree_to_terms"
          v-model="form.is_active"
      />
      <label class="ml-3 form-check-label" for="agree_to_terms">Active  (*)</label
      >
      <small v-if="errors && errors.is_active" class="text-danger">
        {{ errors.is_active[0] }}
      </small>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CurrencyForm",
  props: {
    currencyId: String
  },
  data() {
    return {
      currency: null,
      form: {
        name: '',
        code: null,
        symbol: '',
        value: '',
        is_active: '',
      },
      show: true,
      isLoading: false,
      errors: null,
      message: null
    }
  },
  async created() {
    console.log(this.currencyId)
    if (this.currencyId) {
      axios.get('currencies/' + this.currencyId).then((response) => {
        this.currency = response.data;
        this.form.rate = this.currency.rate;
        this.form.name = this.currency.name;
        this.form.code = this.currency.code;
        this.form.symbol = this.currency.symbol;
        this.form.is_active = this.currency.is_active;
      });
    }
  },
  methods: {
    async onSubmit()
    {
      console.log(this.currencyId)
      if (this.currencyId) {
        await this.updateCurrency()
      } else {
        await this.createCurrency()
      }
    },

    createCurrency()
    {
      this.isLoading = true;
      axios.post('currencies', this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_currencies'})
        }, 1000)
      }).catch((error) => {
        this.isLoading = false;
      });
    },

    updateCurrency()
    {
      this.isLoading = true;
      axios.put('currencies/' + this.currencyId, this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_currencies'})
        }, 1000)

      }).catch((error) => {
        let message = '';
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>